import React, { useState } from 'react'
import { FormApi } from 'final-form'
import { Form } from 'react-final-form'

import { ICommentProps } from 'interfaces'
import { FormDataType, FieldName, formDataInitial } from 'forms/CommentForm/CommentForm'
import { TFile } from 'forms/fields/FieldFile/FieldFile'
import { CommentForm } from 'forms'
import { useMutationComments } from 'containers/Comments/hooks'

export type CommentActionPropType = {
    classes?: string
    commentProps: ICommentProps
    isFocus?: boolean
    onComplete?: () => void
    onError?: (err: any) => void
}

const formDecorators = [(form: FormApi<FormDataType, FormDataType>) => {
    return form.subscribe(({ values }) => {
        if (values[FieldName.sticker] || values[FieldName.file]) {
            form.submit()
        }
    }, { values: true })
}]

const CommentAction: React.FC<CommentActionPropType> = ({
    classes,
    commentProps,
    isFocus,
    onComplete = () => {},
    onError = () => {},
}) => {
    const [{ postId, ...props }] = useState<ICommentProps>(commentProps)
    const [initialValues] = useState<FormDataType>({ ...formDataInitial, ...props })

    const { add: addComment } = useMutationComments()

    const handlerSubmit = (
        {
            comment,
            sticker,
            file,
            ...params
        }: FormDataType,
        { reset }: FormApi<FormDataType, FormDataType>,
    ) => {
        const resetValues = () => setTimeout(() => reset({
            ...initialValues,
            [FieldName.comment]: (sticker || file) ? comment : '',
            [FieldName.sticker]: '',
            [FieldName.file]: '',
        }))

        return addCommentAction({
            postId,
            comment: sticker || file || comment,
            ...params,
        }, resetValues)
    }

    function addCommentAction(params: ICommentProps, actionCallback?: () => void) {
        return addComment.mutateAsync(params, {
            onSuccess: () => {
                if (actionCallback) {
                    actionCallback()
                }
                onComplete()
            },
            onError: (err) => {
                onError(err)
            },
        })
    }

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handlerSubmit}
            decorators={formDecorators}
            mutators={{
                [FieldName.comment]: ([key, value]: [FieldName.comment, string], state, { changeValue }) => {
                    changeValue(state, key, () => value)
                },
                [FieldName.sticker]: ([key, value]: [FieldName.sticker, string], state, { changeValue }) => {
                    changeValue(state, key, () => value)
                },
                [FieldName.file]: ([key, value]: [FieldName.file, TFile[]], state, { changeValue }) => {
                    const [file] = value || []
                    let path = ''

                    if (file.fileData) {
                        path = file.fileData.path
                    }
                    if (file.image) {
                        path += `?sswidth=${file.image.width}&ssheight=${file.image.height}`
                    }
                    if (path) {
                        changeValue(state, key, () => path)
                    }
                },
            }}
            render={({ handleSubmit, submitting }) => (
                <CommentForm
                    classes={classes}
                    isFocus={isFocus}
                    isSubmitting={submitting}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default CommentAction
