import {
    UseQueryOptions,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TRequestError,
    TTicketProps,
    TTicketResponse,
    TAddTicketProps,
    TAddTicketResponse,
} from 'services/SupportService'
import { QueryCacheKeys } from 'enums'
import { SupportService } from 'services'
import { getRequestError } from 'utils/helpers'
import { useAppSelector } from 'store'

export type TFetchFnParams = TTicketProps
export type TQueryFnOpts = UseQueryOptions<
    TTicketResponse,
    [string, TRequestError<TFetchFnParams>],
    TTicketResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.ticket

export default function useFetchTicket(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    const queryKey = user ? `${key}-${user.id}` : key

    return useQuery([queryKey, params], () => {
        return SupportService.fetchTicket(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useMutationTicket() {
    const { t } = useTranslation()

    const add = useMutation<
        TAddTicketResponse,
        [string, TRequestError<TAddTicketProps>],
        TAddTicketProps,
        unknown
    >((props) => {
        return SupportService.addTicket(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        add,
    }
}
