import {
    UseQueryOptions,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TRequestError,
    TPostProps,
    TPostResponse,
    TAddPostProps,
    TAddPostResponse,
    TAddCalendarPostProps,
    TAddCalendarPostResponse,
    TUpdatePostProps,
    TUpdatePostResponse,
    TUpdateCalendarPostProps,
    TUpdateCalendarPostResponse,
    TDeletePostProps,
    TDeletePostResponse,
    TDeleteCalendarPostProps,
    TDeleteCalendarPostResponse,
    TLikePostProps,
    TLikePostResponse,
} from 'services/CommunityService'
import { QueryCacheKeys } from 'enums'
import { CommunityService } from 'services'
import { getRequestError } from 'utils/helpers'
import { useAppSelector } from 'store'
import { useAppQueriesData } from 'queryClient'

export type TFetchFnParams = TPostProps
export type TQueryFnOpts = UseQueryOptions<
    TPostResponse,
    [string, TRequestError<TFetchFnParams>],
    TPostResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.post

/**
 * Hook API feed posts
 */
export default function useFetchPost(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return CommunityService.fetchPost(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useMutationPost() {
    const { t } = useTranslation()

    const addPost = useMutation<
        TAddPostResponse,
        [string, TRequestError<TAddPostProps>],
        TAddPostProps,
        unknown
    >((props) => {
        return CommunityService.addPost(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const addCalendarPost = useMutation<
        TAddCalendarPostResponse,
        [string, TRequestError<TAddCalendarPostProps>],
        TAddCalendarPostProps,
        unknown
    >((props) => {
        return CommunityService.addPost(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const updatePost = useMutation<
        TUpdatePostResponse,
        [string, TRequestError<TUpdatePostProps>],
        TUpdatePostProps,
        unknown
    >((props) => {
        return CommunityService.updatePost(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const updateCalendarPost = useMutation<
        TUpdateCalendarPostResponse,
        [string, TRequestError<TUpdateCalendarPostProps>],
        TUpdateCalendarPostProps,
        unknown
    >((props) => {
        return CommunityService.updatePost(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const deletePost = useMutation<
        TDeletePostResponse,
        [string, TRequestError<TDeletePostProps>],
        TDeletePostProps,
        unknown
    >((props) => {
        return CommunityService.deletePost(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const deleteCalendarPost = useMutation<
        TDeleteCalendarPostResponse,
        [string, TRequestError<TDeleteCalendarPostProps>],
        TDeleteCalendarPostProps,
        unknown
    >((props) => {
        return CommunityService.deletePost(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const likePost = useMutation<
        TLikePostResponse,
        [string, TRequestError<TLikePostProps>],
        TLikePostProps,
        unknown
    >((props) => {
        return CommunityService.likePost(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        addPost,
        addCalendarPost,
        updatePost,
        updateCalendarPost,
        deletePost,
        deleteCalendarPost,
        likePost,
    }
}

export function useSetQueriesDataPost() {
    const user = useAppSelector((state) => state.user)

    const {
        setQueriesData,
        invalidateQueries,
    } = useAppQueriesData<TFetchFnParams, TPostResponse>(`${key}-${user.id}`)

    return {
        setQueriesData,
        invalidateQueries,
    }
}
