import React, { useEffect, useState } from 'react'

import { IPost } from 'interfaces'
import { LoadDataType } from 'components/InfiniteScroll/InfiniteScroll'
import { TCommunityPresidentProps } from 'services/CommunityService'
import { Community } from 'containers'
import { useFetchInfinitePostsPresident, useSetQueriesDataPostsPresident } from 'containers/Community/hooks'
import { Posts } from 'components'
import { showAlertNotify } from 'utils/helpers'

type FeedPostsCommunityPropType = {
    queryParams?: TCommunityPresidentProps
    isPostTile?: boolean
}

const FeedPostsPresident: React.FC<FeedPostsCommunityPropType> = ({
    queryParams = {},
    isPostTile,
}) => {
    const { limit = 10, offset = 0, ...params } = queryParams

    const [isHideLoader, setIsHideLoader] = useState(false)

    const {
        isInitialLoading: isLoading,
        isLoadingError,
        data,
        error,
        fetchNextPage,
    } = useFetchInfinitePostsPresident({
        limit,
        offset,
        ...params,
    }, {
        cacheTime: 60 * 1000, // 1m
        staleTime: 60 * 1000, // 1m
    })

    const { setQueriesData, invalidateQueries } = useSetQueriesDataPostsPresident()

    const handlerOnLoad = ({ page }: LoadDataType) => {
        if (page > 0) {
            fetchNextPage()
        }
    }

    const handlerPostComplete = (value: IPost) => {
        setQueriesData({ limit, offset, ...params }, (cacheData) => {
            if (!cacheData) {
                return undefined
            }
            if ('pages' in cacheData) {
                return {
                    ...cacheData,
                    pages: cacheData.pages.map((page) => {
                        return page.map((post) => (post.id === value.id ? value : post))
                    }),
                }
            }

            return cacheData.map((post) => (post.id === value.id ? value : post))
        })
    }

    const handlerDeletePostComplete = () => {
        invalidateQueries({ limit, offset, ...params })
    }

    const handlerError = (value: string) => {
        showAlertNotify({ type: 'error', message: value })
    }

    useEffect(() => {
        if (isLoadingError) {
            setIsHideLoader(true)
        }
    }, [isLoadingError])

    useEffect(() => {
        if (data) {
            const { pages } = data
            const pagesLen = pages.length

            if (pagesLen && pages[pagesLen - 1].length < limit) {
                setIsHideLoader(true)
            }
        }
    }, [data])

    return (
        <Community
            onLikePostComplete={handlerPostComplete}
            onEditPostComplete={handlerPostComplete}
            onDeletePostComplete={handlerDeletePostComplete}
            onError={handlerError}
        >
            <Posts
                isLoading={isLoading}
                isHideLoader={isHideLoader}
                isPostTile={isPostTile}
                data={data}
                error={error && error[0]}
                onLoad={handlerOnLoad}
            />
        </Community>
    )
}

export default FeedPostsPresident
