import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IPostComment } from 'interfaces'
import { APP_URL } from 'config/app'
import {
    Avatar,
    Button,
    TimeAgo,
    Popover,
    Link,
    MenuList,
    TextContent,
    TextContentEditable,
} from 'components'
import { truncateText } from 'utils/helpers'

import styleBlockComment from 'styles/modules/blockComment.module.css'
import style from './Comment.module.css'

type CommentPropType = {
    data: IPostComment
    isOwn: boolean
    isReply?: boolean
    onCommentReply: (value: IPostComment) => void
    onCommentEdit: (id: number, comment: string) => void
    onCommentDelete: (id: number, message: string) => void
    onCommentRestore: (id: number, message: string) => void
    onBlockUser?: (userId: number, message: string) => void
    onUnBlockUser?: (userId: number, message: string) => void
    onReportAbuse?: (value: IPostComment) => void
}

const COMMENT_PREVIEW_MAX_LENGTH = 300

const Comment: React.FC<CommentPropType> = ({
    data,
    isOwn,
    isReply = false,
    onCommentReply,
    onCommentEdit,
    onCommentDelete,
    onCommentRestore,
    onBlockUser,
    onUnBlockUser,
    onReportAbuse,
}) => {
    const {
        id,
        user,
        is_editable: isEditable,
        deleted_date: deletedDate,
    } = data

    const { t } = useTranslation()

    const [isAttach] = useState(data.comment.includes('http'))
    const [isEdit, setIsEdit] = useState(false)
    const [isShowCommentMore, setIsShowCommentMore] = useState(false)

    const urlUserProfile = useMemo(() => (user ? APP_URL.profile.replace(':id', String(user.id)) : undefined), [user])
    const truncatedComment = useMemo(() => truncateText(data.comment, COMMENT_PREVIEW_MAX_LENGTH), [data.comment])
    const isCommentMore = useMemo(() => data.comment.length > COMMENT_PREVIEW_MAX_LENGTH, [data.comment])

    const handlerSubmitCommentEdit = (comment: string) => {
        setIsEdit(false)
        onCommentEdit(id, comment)
    }

    const handlerClickReply = () => {
        onCommentReply(data)
    }

    const handlerClickEdit = () => {
        setIsEdit((prevState) => !prevState)
    }

    const handlerClickDelete = () => {
        let message = ''

        if (!isOwn) {
            message = t('comments_delete_message').replace('%@', `${user.name ?? ''} ${user.surname ?? ''}`)
        }

        onCommentDelete(id, message)
    }

    const handlerClickRestore = () => {
        let message = ''

        if (!isOwn) {
            message = t('comments_restore_message').replace('%@', `${user.name ?? ''} ${user.surname ?? ''}`)
        }

        onCommentRestore(id, message)
    }

    const handlerClickMore = () => {
        setIsShowCommentMore(true)
    }

    const handlerClickBlockUser = () => {
        if (onBlockUser) {
            onBlockUser(user.id, `${t('post_menu_block_user')} ${user.name ?? ''} ${user.surname ?? ''}`)
        }
    }

    const handlerClickUnBlockUser = () => {
        if (onUnBlockUser) {
            onUnBlockUser(user.id, `${t('post_menu_unblock_user')} ${user.name ?? ''} ${user.surname ?? ''}`)
        }
    }

    const handlerClickReportAbuse = () => {
        if (onReportAbuse) {
            onReportAbuse(data)
        }
    }

    const avatarTpl = (
        <div className={style.avatar}>
            {!isOwn && user?.is_online && (
                <span className={style.online} title={t('user_profile_online')} />
            )}

            <Link url={urlUserProfile}>
                <Avatar
                    src={user?.photo}
                    width={54}
                    height={54}
                    name={user?.name}
                    surname={user?.surname}
                />
            </Link>
        </div>
    )

    return (
        <div className={style.comment}>
            {!isOwn && avatarTpl}

            <div className={cn(
                style.block,
                styleBlockComment.block,
                isOwn ? style.block_right : style.block_left,
                {
                    [style.body_doubleRight]: isOwn && isReply,
                    [style.body_doubleLeft]: !isOwn && isReply,
                },
            )}
            >
                <div className={cn(
                    styleBlockComment.content,
                    isOwn && !deletedDate ? style.content_colorOwn : style.content_colorDefault,
                )}
                >
                    <div className={cn(
                        styleBlockComment.user,
                        {
                            [style.reply]: isReply,
                            [styleBlockComment.user_colorWhite]: isOwn && !deletedDate,
                            [styleBlockComment.user_colorGray]: deletedDate,
                        },
                    )}
                    >
                        {`${user?.name ?? ''} ${user?.surname ?? ''}`}
                    </div>

                    <div className={cn(
                        styleBlockComment.text,
                        {
                            [styleBlockComment.text_colorWhite]: isOwn && !deletedDate,
                            [styleBlockComment.text_colorGray]: deletedDate,
                        },
                    )}
                    >
                        {!user.is_blocked && deletedDate && (
                            <div className={style.textBlocked}>
                                {t('single_word_deleted')}
                            </div>
                        )}

                        {user.is_blocked && (
                            <div className={style.textBlocked}>
                                {t('blocked_comment_text')}
                            </div>
                        )}

                        {!user.is_blocked && !deletedDate && isEdit && (
                            <TextContentEditable
                                classes={style.textEditable}
                                value={data.comment}
                                onSubmitByEnter={handlerSubmitCommentEdit}
                            />
                        )}

                        {!user.is_blocked && !deletedDate && !isEdit && (
                            <>
                                <TextContent content={isShowCommentMore ? data.comment : truncatedComment} />
                                {' '}
                                {isCommentMore && !isShowCommentMore && (
                                    <Button
                                        classes={cn(
                                            style.textMore,
                                            styleBlockComment.text,
                                            {
                                                [styleBlockComment.text_colorWhite]: isOwn && !deletedDate,
                                                [styleBlockComment.text_colorGray]: deletedDate,
                                            },
                                        )}
                                        styleType="text"
                                        text={t('tab_more')}
                                        onClick={handlerClickMore}
                                    />
                                )}
                            </>
                        )}
                    </div>

                    {!deletedDate && (
                        <div className={cn(styleBlockComment.date, { [styleBlockComment.date_colorWhite]: isOwn })}>
                            <TimeAgo time={data.created} />
                        </div>
                    )}
                </div>

                <div className={style.actions}>
                    {isEditable && (
                        deletedDate ? (
                            <Button
                                classes={cn(style.action, style.actionText, style.actionText_success)}
                                styleType="text"
                                text={t('chat_message_menu_recover')}
                                onClick={handlerClickRestore}
                            />
                        ) : (
                            <Button
                                classes={cn(style.action, style.actionText, style.actionText_danger)}
                                styleType="text"
                                text={t('chat_message_menu_delete')}
                                onClick={handlerClickDelete}
                            />
                        )
                    )}

                    {isOwn ? (
                        <Popover classes={style.action} trigger={<Popover.TriggerDots />} side="center">
                            <MenuList classes={style.action}>
                                {!deletedDate && !isEdit && (
                                    <MenuList.Item onClick={handlerClickReply}>
                                        {t('ask')}
                                    </MenuList.Item>
                                )}
                                {isEditable && ( // TODO company_id в комментарии совпадает с company_id в авторизации
                                    <>
                                        {/* TODO ? edit if is deleted */}
                                        {!isAttach && (
                                            isEdit ? (
                                                <MenuList.Item onClick={handlerClickEdit}>
                                                    {t('cancel')}
                                                </MenuList.Item>
                                            ) : (
                                                <MenuList.Item onClick={handlerClickEdit}>
                                                    {t('edit')}
                                                </MenuList.Item>
                                            )
                                        )}

                                        {deletedDate ? (
                                            <MenuList.Item onClick={handlerClickRestore}>
                                                {t('chat_message_menu_recover')}
                                            </MenuList.Item>
                                        ) : (
                                            <MenuList.Item onClick={handlerClickDelete}>
                                                <span className={cn(style.menuItem, style.menuItem_danger)}>
                                                    {t('chat_message_menu_delete')}
                                                </span>
                                            </MenuList.Item>
                                        )}
                                    </>
                                )}
                            </MenuList>
                        </Popover>
                    ) : (
                        <>
                            {!deletedDate && (
                                <Button
                                    classes={cn(style.action, style.actionText, style.actionText_bold)}
                                    styleType="text"
                                    text={t('ask')}
                                    onClick={handlerClickReply}
                                />
                            )}

                            <Popover classes={style.action} trigger={<Popover.TriggerDots />} side="center">
                                <MenuList>
                                    {user.is_blocked ? (
                                        <MenuList.Item onClick={handlerClickUnBlockUser}>
                                            {t('post_menu_unblock_user')}
                                        </MenuList.Item>
                                    ) : (
                                        <MenuList.Item onClick={handlerClickBlockUser}>
                                            {t('post_menu_block_user')}
                                        </MenuList.Item>
                                    )}
                                    <MenuList.Item onClick={handlerClickReportAbuse}>
                                        {t('report_content')}
                                    </MenuList.Item>
                                </MenuList>
                            </Popover>
                        </>
                    )}
                </div>
            </div>

            {isOwn && avatarTpl}
        </div>
    )
}

export default Comment
