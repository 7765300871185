import React, { useMemo, useState } from 'react'
import { useHistory, generatePath } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    IPost,
    IPostOrder,
    ICommunity,
    ITicketItem,
} from 'interfaces'
import { TDeletePostProps } from 'services/CommunityService'
import { TBlockUserProps, TUnblockUserProps } from 'services/NetworkService'
import { CardSize } from 'enums'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { Context, ContextType } from 'containers/Community/Context'
import { CardContainer } from 'layout'
import { useFetchCommunity, useFetchPostLikes, useMutationCommunity } from 'containers/Community/hooks'
import { useMutationBlocks } from 'containers/Network/hooks'
import { useInvalidateProfile } from 'containers/User/hooks'
import { useFetchOrder } from 'containers/Market/hooks'
import { PostLikeUserCard, PostCheck } from 'containers/Community/components'
import {
    unsubscribeCompany,
    unsubscribeStore,
    unsubscribeStoreUser,
    unsubscribeUser,
} from 'containers/Network/network-actions'
import {
    Loader,
    Button,
    Modal,
    List,
} from 'components'
import { formDataInitial } from 'forms/PostForm/PostForm'
import { PostAction, ReportAbusePostAction } from 'form-actions'
import { useWindowResize, useAlertDialog } from 'hooks'
import { PostDto } from 'dto'
import styleOverlay from 'styles/modules/overlay.module.css'
import { useMutationPost } from './hooks'
import style from './Community.module.css'

type CommunityPropType = {
    onLikePostComplete?: (value: IPost) => void
    onEditPostComplete?: (value: IPost) => void
    onDeletePostComplete?: (value: IPost) => void
    onBlockUserComplete?: (value: string) => void
    onReportAbuseComplete?: (value: ITicketItem) => void
    onUnsubscribeCommunityComplete?: (value: ICommunity) => void
    onError?: (value: string) => void
}

const Community: React.FC<CommunityPropType> = ({
    children,
    onLikePostComplete = () => {},
    onEditPostComplete = () => {},
    onDeletePostComplete = () => {},
    onBlockUserComplete = () => {},
    onReportAbuseComplete = () => {},
    onUnsubscribeCommunityComplete = () => {},
    onError = () => {},
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const [windowWidth] = useWindowResize()
    const { showAlertDialog } = useAlertDialog()

    const [postProps, setPostProps] = useState(formDataInitial)
    const [postData, setPostData] = useState<IPost>()
    const [postOrderData, setPostOrderData] = useState<IPostOrder>()

    const [isOpenModalPostLikes, setIsOpenModalPostLikes] = useState(false)
    const [isOpenModalPostForm, setIsOpenModalPostForm] = useState(false)
    const [isOpenModalAbuseForm, setIsOpenModalAbuseForm] = useState(false)
    const [isOpenModalCheck, setIsOpenModalCheck] = useState(false)

    const [context, setContext] = useState<ContextType>({
        isLoading: false,
        onLikePost: handlerLikePost,
        onLikesPost: handlerLikesPost,
        onEditPost: handlerEditPost,
        onDeletePost: handlerDeletePost,
        onReportPost: handlerReportPost,
        onUnsubscribeUser: handlerUnsubscribePostUser,
        onUnsubscribeUserInStore: handlerUnsubscribeUserInStore,
        onUnsubscribeStore: handlerUnsubscribePostStore,
        onUnsubscribeCommunity: handlerUnsubscribeCommunity,
        onUnsubscribeCompany: handlerUnsubscribePostCompany,
        onBlockUser: handlerBlockPostUser,
        onShowChek: handlerShowChek,
    })

    const { data: dataCommunity } = useFetchCommunity({
        can_publish: true,
    }, {
        enabled: isOpenModalPostForm,
    })

    const {
        isInitialLoading: isLoadingPostLikes,
        data: dataPostLikes,
    } = useFetchPostLikes({
        id: postData?.id ?? 0,
    }, {
        enabled: isOpenModalPostLikes && !!postData,
    })

    const {
        isInitialLoading: isLoadingCheck,
        data: dataOrder,
    } = useFetchOrder({
        storeId: postOrderData?.store_id ?? 0,
        orderId: postOrderData?.id ?? 0,
    }, {
        enabled: isOpenModalCheck && !!postOrderData,
    })

    const { unsubscribe: unsubscribeCommunity } = useMutationCommunity()

    const { deletePost, likePost } = useMutationPost()

    const { blockUser, unblockUser } = useMutationBlocks()

    const { invalidate: invalidateProfile } = useInvalidateProfile()

    const itemPostLikeSize = useMemo(() => {
        if (windowWidth > BREAKPOINTS.tabletLandscape) {
            return CardSize.thirdWidth
        }
        return CardSize.halfWidth
    }, [windowWidth])

    const handlerEditPostComplete = (value: IPost) => {
        setIsOpenModalPostForm(false)
        onEditPostComplete(value)
    }

    const handlerReportAbuseComplete = (value: ITicketItem) => {
        setIsOpenModalAbuseForm(false)
        onReportAbuseComplete(value)
    }

    const handlerAddOrderToBasket = () => {
        if (postOrderData) {
            const { id: orderId, store_id: storeId } = postOrderData
            history.push(generatePath(APP_URL.basketFromOrder, { id: storeId, orderId }))
        }
    }

    function handlerLikePost(post: IPost) {
        likePostAction(post)
    }

    function handlerLikesPost(post: IPost) {
        setPostData(post)
        setIsOpenModalPostLikes(true)
    }

    function handlerEditPost(post: IPost) {
        setPostData(post)
        setPostProps({ ...formDataInitial, ...PostDto.getPostProps(post) })
        setIsOpenModalPostForm(true)
    }

    function handlerDeletePost(post: IPost, message: string = '') {
        showAlertDialog({
            message,
            payload: { post },
            onConfirm: removePost,
        })
    }

    function handlerReportPost(post: IPost) {
        setPostData(post)
        setIsOpenModalAbuseForm(true)
    }

    function handlerUnsubscribePostUser(userId: number, message: string = '') {
        showAlertDialog({
            message,
            onConfirm: () => unsubscribeUserAction(userId),
        })
    }

    function handlerUnsubscribeUserInStore(userId: number, storeId: number, message: string = '') {
        showAlertDialog({
            message,
            onConfirm: () => unsubscribeStoreUserAction(storeId, userId),
        })
    }

    function handlerUnsubscribePostStore(storeId: number, message: string = '') {
        showAlertDialog({
            message,
            onConfirm: () => unsubscribePostStoreAction(storeId),
        })
    }

    function handlerUnsubscribeCommunity(communityId: number, message: string = '') {
        showAlertDialog({
            message,
            onConfirm: () => unsubscribeCommunityAction(communityId),
        })
    }

    function handlerUnsubscribePostCompany(companyId: number, message: string = '') {
        showAlertDialog({
            message,
            onConfirm: () => unsubscribePostCompanyAction(companyId),
        })
    }

    function handlerBlockPostUser(userId: number, message: string) {
        showAlertDialog({
            message,
            onConfirm: () => blockPostUserAction({ userId }),
        })
    }

    function handlerShowChek(order: IPostOrder) {
        setPostOrderData(order)
        setIsOpenModalCheck(true)
    }

    function removePost({ post }: { post: IPost }) {
        const { id, ...props } = PostDto.getPostProps(post)

        if (id) {
            deletePostAction({ id, ...props })
        }
    }

    function likePostAction(post: IPost) {
        setContext((prevState) => ({ ...prevState, isLoading: true }))
        likePost.mutate({ id: post.id }, {
            onSuccess: (res) => {
                if (res) {
                    // TODO invalidate post in cache
                    onLikePostComplete({ ...post, ...res })
                }
            },
            onError: ([errorText]) => {
                onError(errorText)
            },
            onSettled: () => {
                setContext((prevState) => ({ ...prevState, isLoading: false }))
            },
        })
    }

    function deletePostAction(params: TDeletePostProps) {
        deletePost.mutate({ ...params, isPublished: false }, {
            onSuccess: (res) => {
                if (res) {
                    onDeletePostComplete(res)
                }
            },
            onError: ([errorText]) => {
                onError(errorText)
            },
        })
    }

    function unsubscribeUserAction(userId: number) {
        dispatch(unsubscribeUser({ userId }))
    }

    function unsubscribeStoreUserAction(storeId: number, userId: number) {
        dispatch(unsubscribeStoreUser({ storeId, userId }))
    }

    function unsubscribePostStoreAction(storeId: number) {
        dispatch(unsubscribeStore({ storeId }))
    }

    function unsubscribeCommunityAction(communityId: number) {
        unsubscribeCommunity.mutate({ communityId }, {
            onSuccess: (res) => {
                if (Array.isArray(res)) {
                    const [community] = res
                    onUnsubscribeCommunityComplete(community)
                }
            },
            onError: ([errorText]) => {
                onError(errorText)
            },
        })
    }

    function unsubscribePostCompanyAction(companyId: number) {
        dispatch(unsubscribeCompany({ companyId }))
    }

    function blockPostUserAction(params: TBlockUserProps) {
        blockUser.mutate(params, {
            onSuccess: (res) => {
                invalidateProfile(params)
                onBlockUserComplete(res)
            },
            onError: ([errorText]) => {
                onError(errorText)
            },
        })
    }

    // TODO
    // eslint-disable-next-line
    function unblockUserAction(params: TUnblockUserProps) {
        unblockUser.mutate(params, {
            onSuccess: (res) => {
                // invalidateProfile()
                // showAlertNotify({ type: 'success', message: res })
            },
            onError: ([errorText]) => {
                // showAlertNotify({ type: 'error', message: errorText })
            },
        })
    }

    return (
        <>
            <Context.Provider value={context}>
                {children}
            </Context.Provider>

            <Modal
                isOpen={isOpenModalPostForm}
                size="medium"
                classesOverlay={styleOverlay.overlay_light}
                onClose={() => setIsOpenModalPostForm(false)}
            >
                <Modal.Header
                    isCloseButton
                    titlePos="left"
                    title={t('ui_posts_edit_post_title')}
                />
                <PostAction
                    isActiveCommunity={false}
                    postProps={postProps}
                    communities={dataCommunity}
                    onComplete={handlerEditPostComplete}
                />
            </Modal>

            <Modal
                isOpen={isOpenModalAbuseForm}
                size="medium"
                classesOverlay={styleOverlay.overlay_light}
                onClose={() => setIsOpenModalAbuseForm(false)}
            >
                <Modal.Header
                    isCloseButton
                    classes={style.modalHeader}
                    classesTitle={style.modalTitle}
                    titlePos="left"
                    title={t('report_content')}
                />
                <Modal.Body classes={style.modalBody}>
                    {postData ? (
                        <ReportAbusePostAction
                            data={postData}
                            onError={onError}
                            onSuccess={handlerReportAbuseComplete}
                        />
                    ) : (
                        t('update_error')
                    )}
                </Modal.Body>
            </Modal>

            <Modal
                isOpen={isOpenModalPostLikes}
                size="medium"
                onClose={() => setIsOpenModalPostLikes(false)}
            >
                <Modal.Header
                    isCloseButton
                    titlePos="left"
                    title={`${t('likes')} ${dataPostLikes?.length || ''}`}
                />
                <Modal.Body background>
                    {isLoadingPostLikes && (
                        <Loader />
                    )}
                    {!isLoadingPostLikes && dataPostLikes && (
                        <CardContainer>
                            <List limit={12}>
                                {dataPostLikes.map((item) => (
                                    <PostLikeUserCard
                                        data={item}
                                        size={itemPostLikeSize}
                                        key={item.id}
                                    />
                                ))}
                            </List>
                        </CardContainer>
                    )}
                </Modal.Body>
            </Modal>

            <Modal
                isOpen={isOpenModalCheck}
                size="medium"
                classesOverlay={styleOverlay.overlay_light}
                onClose={() => setIsOpenModalCheck(false)}
            >
                <Modal.Header
                    isCloseButton
                    titlePos="center"
                    title={t('market_summary_title')}
                />
                <Modal.Body>
                    {isLoadingCheck && (
                        <Loader />
                    )}
                    {!isLoadingCheck && dataOrder && postOrderData && (
                        <PostCheck
                            data={dataOrder}
                            postOrder={postOrderData}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        fullWidth
                        size="size44"
                        onClick={handlerAddOrderToBasket}
                    >
                        {t('copy_order_to_basket')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Community
