import {
    UseQueryOptions,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TRequestError,
    TBlocksResponse,
    TBlockUserProps,
    TBlockUserResponse,
    TUnblockUserProps,
    TUnblockUserResponse,
} from 'services/NetworkService'
import { QueryCacheKeys } from 'enums'
import { NetworkService } from 'services'
import { getRequestError } from 'utils/helpers'
import { useAppSelector } from 'store'

export type TQueryFnOpts = UseQueryOptions<
    TBlocksResponse,
    [string, TRequestError<never>],
    TBlocksResponse,
    [string]
>

const key = QueryCacheKeys.blocks

/**
 * Hook API blocked user list
 */
export default function useFetchBlocks(opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user.id}`], () => {
        return NetworkService.fetchBlocks()
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useMutationBlocks() {
    const { t } = useTranslation()

    const blockUser = useMutation<
        TBlockUserResponse,
        [string, TRequestError<TBlockUserProps>],
        TBlockUserProps,
        unknown
    >((props) => {
        return NetworkService.blockUser(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const unblockUser = useMutation<
        TUnblockUserResponse,
        [string, TRequestError<TUnblockUserProps>],
        TUnblockUserProps,
        unknown
    >((props) => {
        return NetworkService.unBlockUser(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        blockUser,
        unblockUser,
    }
}
