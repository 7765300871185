import { IUserSubscribe } from 'interfaces'
import { API_URL } from 'config/api'
import requestClient, { RequestError } from 'utils/requestClient'
import { parseTpl } from 'utils/helpers'

export type TNetworkServiceError = {
    status: number
    type: string
    title: string // example: An error occurred
    detail: string // example: Not Found
}

export type TRequestError<RequestParams> = RequestError<TNetworkServiceError, RequestParams>

export type TSubscribeProps = {
    page: number
    limit: number
    offset: number
}

export type TSubscribeUserProps = {
    id: number
    member_id: string
    phone?: string // +79101234567
}

export type TUnsubscribeUserProps = {
    userId: number
}

export type unsubscribeStorePropType = {
    storeId: number
}

export type unsubscribeStoreUserPropType = {
    storeId: number
    userId: number
}

export type unsubscribeCompanyPropType = {
    companyId: number
}

export type TBlockUserProps = {
    userId: number
}

export type TUnblockUserProps = {
    userId: number
}

export type TSubscribeResponse = unknown

export type TSubscribeUserResponse = IUserSubscribe

export type TUnsubscribeUserResponse = string

export type TBlocksResponse = string

export type TBlockUserResponse = string

export type TUnblockUserResponse = string

class NetworkService {
    static fetchSubscribe(params: TSubscribeProps) {
        return requestClient<TSubscribeResponse>(API_URL.subscribe, { params })
    }

    static subscribeUser(params: TSubscribeUserProps) {
        return requestClient<TSubscribeUserResponse>(`${API_URL.subscribe}/`, { method: 'post', data: params })
    }

    static unsubscribeUser({ userId }: TUnsubscribeUserProps) {
        const url = parseTpl(API_URL.unsubscribeUser, { userId })
        return requestClient<TUnsubscribeUserResponse>(url, { method: 'delete' })
    }

    static unsubscribeStore({ storeId }: unsubscribeStorePropType) {
        const url = parseTpl(API_URL.unsubscribeStore, { store_id: storeId })
        return requestClient<null>(url, { method: 'post' })
    }

    static unsubscribeStoreUser({ storeId, userId }: unsubscribeStoreUserPropType) {
        const url = parseTpl(API_URL.unsubscribeStoreUser, { store_id: storeId, user_id: userId })
        return requestClient<null>(url, { method: 'post' })
    }

    static unsubscribeCompany({ companyId }: unsubscribeCompanyPropType) {
        return requestClient<null>(API_URL.unsubscribeCompany, { method: 'post', data: { company_id: companyId } })
    }

    static fetchBlocks() {
        return requestClient<TBlocksResponse>(API_URL.blocks)
    }

    static blockUser({ userId }: TBlockUserProps) {
        return requestClient<TBlockUserResponse>(API_URL.blockUser, { method: 'post', data: { id: userId } })
    }

    static unBlockUser({ userId }: TUnblockUserProps) {
        const url = parseTpl(API_URL.unBlockUser, { id: userId })
        return requestClient<TUnblockUserResponse>(url, { method: 'delete' })
    }
}

export default NetworkService
