import {
    UseQueryOptions,
    UseInfiniteQueryOptions,
    useInfiniteQuery,
    useQuery,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TRequestError,
    TCommunityPresidentProps,
    TPostsResponse,
} from 'services/CommunityService'
import { QueryCacheKeys } from 'enums'
import { CommunityService } from 'services'
import { getRequestError } from 'utils/helpers'
import { useAppSelector } from 'store'
import { useAppQueriesData } from 'queryClient'

export type TFetchFnParams = TCommunityPresidentProps
export type TQueryFnOpts = UseQueryOptions<
    TPostsResponse,
    [string, TRequestError<TFetchFnParams>],
    TPostsResponse,
    [string, TFetchFnParams]
>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    TPostsResponse,
    [string, TRequestError<TFetchFnParams>],
    TPostsResponse,
    TPostsResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.postsPresident

/**
 * Hook API president post list
 */
export default function useFetchPostsPresident(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return CommunityService.fetchCommunityPresident(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useFetchInfinitePostsPresident(initParams: TFetchFnParams, opts: TInfiniteQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useInfiniteQuery([`${key}-${user.id}`, initParams], ({ pageParam = initParams }) => {
        return CommunityService.fetchCommunityPresident(pageParam)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, {
        getNextPageParam: (lastPage, pages) => {
            return { ...initParams, offset: pages.length * lastPage.length }
        },
        ...opts,
    })
}

export function useSetQueriesDataPostsPresident() {
    const user = useAppSelector((state) => state.user)

    const {
        setQueriesData,
        invalidateQueries,
    } = useAppQueriesData<TFetchFnParams, TPostsResponse>(`${key}-${user.id}`)

    return {
        setQueriesData,
        invalidateQueries,
    }
}
