import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { MY_FEED_ID } from 'config/app'
import { IRootState, IPost } from 'interfaces'
import { MenuList } from 'components/index'
import { parseTpl } from 'utils/helpers'
import { useAppSelector } from 'store'
import style from 'components/Post/Post.module.css'

type PostDropDownMenuPropType = {
    post: IPost
    isShowAuthor: boolean
    onClickEditPost: () => void
    onClickDeletePost: () => void
    onClickUnsubscribeUser: () => void
    onClickUnsubscribeUserInStore: () => void
    onClickUnsubscribeStore: () => void
    onClickBlockUser: () => void
    onClickUnsubscribeCommunity: () => void
    onClickUnsubscribeCompany: () => void
    onClickReport: () => void
}

const PARSE_TEXT_TPL_PARAMS = { prefix: '', suffix: '' }

const PostDropDownMenu: React.FC<PostDropDownMenuPropType> = ({
    post,
    isShowAuthor,
    onClickEditPost,
    onClickDeletePost,
    onClickUnsubscribeUser,
    onClickUnsubscribeUserInStore,
    onClickUnsubscribeStore,
    onClickBlockUser,
    onClickUnsubscribeCommunity,
    onClickUnsubscribeCompany,
    onClickReport,
}) => {
    const { t } = useTranslation()

    const user = useAppSelector((state) => state.user)

    // TODO ? проверить необходимость, т.к. посты удаляются из state
    const unsubscribedUsers = useAppSelector((state: IRootState) => state.network.unsubscribedUsers)
    const unsubscribedStores = useAppSelector((state: IRootState) => state.network.unsubscribedStores)
    const unsubscribedStoreUsers = useAppSelector((state: IRootState) => state.network.unsubscribedStoreUsers)

    const isAuthor = useMemo(() => (post?.user ? user.id === post.user.id : false), [post])

    const textUnsubscribeCommunity = useMemo(() => {
        const { community } = post || {}
        const { title = '' } = community || {}

        return parseTpl(t('post_menu_unsubscribe_community'), {
            '%s': `<span class="${style.textHighlight}">${title}</span>`,
        }, PARSE_TEXT_TPL_PARAMS)
    }, [post])

    const textUnsubscribeStore = useMemo(() => {
        const { order } = post
        const { store_name = '' } = order || {}

        return parseTpl(t('post_menu_unsubscribe_from_store'), {
            '%s': `<span class="${style.textHighlight}">${store_name}</span>`,
        }, PARSE_TEXT_TPL_PARAMS)
    }, [post])

    const textUnsubscribeCompany = useMemo(() => {
        const { community } = post || {}
        const { company_name = '' } = community || {}

        return parseTpl(t('post_menu_unsubscribe_company'), {
            '%s': `<span class="${style.textHighlight}">${company_name}</span>`,
        }, PARSE_TEXT_TPL_PARAMS)
    }, [post])

    const textUnsubscribeUserInStore = useMemo(() => {
        const { user: author, order } = post || {}
        const { store_name = '' } = order || {}
        const { name = '', surname = '' } = author || {}

        return parseTpl(t('post_menu_unsubscribe_user_in_store'), {
            '%0': `<span class="${style.textHighlight}">${name} ${surname}</span>`,
            '%1': `<span class="${style.textHighlight}">${store_name}</span>`,
        }, PARSE_TEXT_TPL_PARAMS)
    }, [post])

    function isUnsubscribeUser(userId: number) {
        return unsubscribedUsers.includes(userId)
    }

    function isUnsubscribedStores(storeId: number) {
        return unsubscribedStores.includes(storeId)
    }

    function isUnsubscribeUserInStore(storeId: number, userId: number) {
        return unsubscribedStoreUsers[storeId] ? unsubscribedStoreUsers[storeId].includes(userId) : false
    }

    return (
        <MenuList>
            {isAuthor && (
                <>
                    <MenuList.Item onClick={onClickEditPost}>
                        {t('post_menu_edit_post')}
                    </MenuList.Item>
                    <MenuList.Item onClick={onClickDeletePost}>
                        {t('post_menu_delete_post')}
                    </MenuList.Item>
                </>
            )}
            {!isAuthor && isShowAuthor && !isUnsubscribeUser(post.user.id) && (
                <MenuList.Item onClick={onClickUnsubscribeUser}>
                    {t('post_menu_unsubscribe_user')}
                </MenuList.Item>
            )}
            {!isAuthor && post.order && !isUnsubscribeUserInStore(post.order.store_id, post.user.id) && (
                <>
                    <MenuList.Item onClick={onClickUnsubscribeUserInStore}>
                        <span dangerouslySetInnerHTML={{ __html: textUnsubscribeUserInStore }} />
                    </MenuList.Item>
                </>
            )}
            {!isAuthor && post.order && !isUnsubscribedStores(post.order.store_id) && (
                <MenuList.Item onClick={onClickUnsubscribeStore}>
                    <span dangerouslySetInnerHTML={{ __html: textUnsubscribeStore }} />
                </MenuList.Item>
            )}
            {!isAuthor && post.community?.company_id && (
                <MenuList.Item onClick={onClickUnsubscribeCompany}>
                    <span dangerouslySetInnerHTML={{ __html: textUnsubscribeCompany }} />
                </MenuList.Item>
            )}
            {!isAuthor && isShowAuthor && (
                <MenuList.Item onClick={onClickBlockUser}>
                    {t('post_menu_block_user')}
                </MenuList.Item>
            )}
            {!isShowAuthor && post.community.id !== MY_FEED_ID && (
                <MenuList.Item onClick={onClickUnsubscribeCommunity}>
                    <span dangerouslySetInnerHTML={{ __html: textUnsubscribeCommunity }} />
                </MenuList.Item>
            )}
            {!isAuthor && (
                <MenuList.Item onClick={onClickReport}>
                    {t('report_content')}
                </MenuList.Item>
            )}
        </MenuList>
    )
}

export default PostDropDownMenu
