import React from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    Button,
    Checkbox,
    Radio,
    Textarea,
} from 'components'
import styleForm from 'styles/modules/form.module.css'
import style from './ReportAbuseCommentForm.module.css'

export enum FieldName {
    category = 'category',
    message = 'message',
    isReportComment = 'isReportComment',
    isUnsubscribe = 'isUnsubscribe',
    isBlock = 'isBlock',
}

export type FormDataType = {
    [FieldName.category]: number
    [FieldName.message]: string
    [FieldName.isReportComment]: boolean
    [FieldName.isUnsubscribe]?: boolean
    [FieldName.isBlock]?: boolean
}

type AbuseFromPropType = {
    classes?: string
    userText?: string
    isSubmitting: boolean
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export const formDataInitial: FormDataType = {
    [FieldName.category]: 30, // id ticket category - report inappropriate content
    [FieldName.isReportComment]: true,
    [FieldName.message]: '',
}

const ReportAbuseCommentForm: React.FC<AbuseFromPropType> = ({
    classes,
    userText = '',
    isSubmitting,
    onSubmit,
}) => {
    const { t } = useTranslation()

    return (
        <form className={cn(classes, { [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
            <div className={cn(styleForm.row, styleForm.row_24)}>
                <div className={style.desc}>
                    {t('support_screen_complaint_info_text')}
                </div>
            </div>
            <div className={cn(styleForm.row, styleForm.row_24)}>
                <Field
                    <FormDataType[FieldName.isReportComment]>
                    name={FieldName.isReportComment}
                    render={({ input }) => (
                        <div className={cn(styleForm.group, styleForm.group_alignCenter, styleForm.group_spaceBetween)}>
                            <div className={styleForm.groupItem}>
                                {t('complain_about_comment')}
                            </div>
                            <div className={styleForm.groupItem}>
                                <Radio
                                    size="small"
                                    checked={input.value === true}
                                    name={input.name}
                                    onChange={(e) => input.onChange(e.target.checked)}
                                />
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_24)}>
                <Field
                    <FormDataType[FieldName.isReportComment]>
                    name={FieldName.isReportComment}
                    render={({ input }) => (
                        <div className={cn(styleForm.group, styleForm.group_alignCenter, styleForm.group_spaceBetween)}>
                            <div className={styleForm.groupItem}>
                                {t('complain_about_user').replace('%s', userText)}
                            </div>
                            <div className={styleForm.groupItem}>
                                <Radio
                                    size="small"
                                    checked={input.value === false}
                                    name={input.name}
                                    onChange={(e) => input.onChange(e.target.checked && false)}
                                />
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_24)}>
                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_normal)}>
                    {t('report_details')}
                </div>
                <Field
                    name={FieldName.message}
                    render={({ input }) => (
                        <div className={styleForm.field}>
                            <Textarea
                                {...input}
                                isAutoHeight
                                styleType="dynamic"
                                dynamicPlaceholder={t('optional')}
                                rows={2}
                                limit={1000}
                            />
                        </div>
                    )}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_24)}>
                <Field
                    name={FieldName.isUnsubscribe}
                    render={({ input }) => (
                        <div className={cn(styleForm.group, styleForm.group_alignCenter, styleForm.group_spaceBetween)}>
                            <div className={styleForm.groupItem}>
                                {`${t('Unsubscribe')} ${userText}`}
                            </div>
                            <div className={styleForm.groupItem}>
                                <Checkbox
                                    styleType="small"
                                    checked={!!input.value}
                                    name={input.name}
                                    onChange={(e) => input.onChange(e.target.checked)}
                                />
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_24)}>
                <Field
                    name={FieldName.isBlock}
                    render={({ input }) => (
                        <div className={cn(styleForm.group, styleForm.group_alignCenter, styleForm.group_spaceBetween)}>
                            <div className={styleForm.groupItem}>
                                {`${t('block')} ${userText}`}
                            </div>
                            <div className={styleForm.groupItem}>
                                <Checkbox
                                    styleType="small"
                                    checked={!!input.value}
                                    name={input.name}
                                    onChange={(e) => input.onChange(e.target.checked)}
                                />
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className={cn(styleForm.controls, styleForm.controls_40, style.controls)}>
                <Button
                    fullWidth
                    textUpper
                    classes={style.control}
                    size="size46"
                    type="submit"
                    text={t('send')}
                />
            </div>
        </form>
    )
}

export default ReportAbuseCommentForm
