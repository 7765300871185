import {
    UseQueryOptions,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TRequestError,
    TSubscribeProps,
    TSubscribeResponse,
    TSubscribeUserProps,
    TSubscribeUserResponse,
    TUnsubscribeUserProps,
    TUnsubscribeUserResponse,
} from 'services/NetworkService'
import { QueryCacheKeys } from 'enums'
import { NetworkService } from 'services'
import { getRequestError } from 'utils/helpers'
import { useAppSelector } from 'store'

export type TFetchFnParams = TSubscribeProps
export type TQueryFnOpts = UseQueryOptions<
    TSubscribeResponse,
    [string, TRequestError<TFetchFnParams>],
    TSubscribeResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.subscribe

/**
 * Hook API subscribe list
 */
export default function useFetchSubscribe(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return NetworkService.fetchSubscribe(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useMutationSubscribe() {
    const { t } = useTranslation()

    const subscribeUser = useMutation<
        TSubscribeUserResponse,
        [string, TRequestError<TSubscribeUserProps>],
        TSubscribeUserProps,
        unknown
    >((props) => {
        return NetworkService.subscribeUser(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const unsubscribeUser = useMutation<
        TUnsubscribeUserResponse,
        [string, TRequestError<TUnsubscribeUserProps>],
        TUnsubscribeUserProps,
        unknown
    >((props) => {
        return NetworkService.unsubscribeUser(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        subscribeUser,
        unsubscribeUser,
    }
}
