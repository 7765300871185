import {
    UseQueryOptions,
    UseInfiniteQueryOptions,
    useQuery,
    useInfiniteQuery,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TRequestError,
    TCommunityDefaultImagesProps,
    TCommunityDefaultImagesResponse,
} from 'services/CommunityService'
import { QueryCacheKeys } from 'enums'
import { CommunityService } from 'services'
import { getRequestError } from 'utils/helpers'
import { useAppSelector } from 'store'

export type TFetchFnParams = TCommunityDefaultImagesProps
export type TQueryFnOpts = UseQueryOptions<
    TCommunityDefaultImagesResponse,
    [string, TRequestError<TFetchFnParams>],
    TCommunityDefaultImagesResponse,
    [string, TFetchFnParams]
>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    TCommunityDefaultImagesResponse,
    [string, TRequestError<TFetchFnParams>],
    TCommunityDefaultImagesResponse,
    TCommunityDefaultImagesResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.communityDefaultImages

/**
 * Hook API post images
 */
export default function useFetchCommunityDefaultImages(params: TFetchFnParams = {}, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return CommunityService.fetchCommunityDefaultImages(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useFetchInfiniteCommunityDefaultImages(initParams: TFetchFnParams, opts: TInfiniteQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useInfiniteQuery([`${key}-${user.id}`, initParams], ({ pageParam = initParams }) => {
        return CommunityService.fetchCommunityDefaultImages(pageParam)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, {
        getNextPageParam: (lastPage, pages) => {
            return { ...initParams, offset: pages.length * lastPage.length }
        },
        ...opts,
    })
}
