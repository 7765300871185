import React, { useMemo } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { IPostComment, ITicketItem } from 'interfaces'
import { FormDataType, FieldName, formDataInitial } from 'forms/ReportAbuseCommentForm/ReportAbuseCommentForm'
import { TAddTicketProps } from 'services/SupportService'
import { TBlockUserProps, TUnsubscribeUserProps } from 'services/NetworkService'
import { APP_ID, APP_VERSION } from 'config/app'
import { ReportAbuseCommentForm } from 'forms'
import { useMutationTicket } from 'containers/Support/hooks'
import { useMutationBlocks, useMutationSubscribe } from 'containers/Network/hooks'
import { useUserGUID } from 'hooks'
import { useAppSelector } from 'store'

type AbuseActionPropType = {
    data: IPostComment
    onError?: (value: string) => void
    onSuccess?: (value: ITicketItem) => void
}

const ReportAbuseCommentAction: React.FC<AbuseActionPropType> = ({
    data,
    onSuccess = () => {},
    onError = () => {},
}) => {
    const { t } = useTranslation()
    const guid = useUserGUID()

    const user = useAppSelector((state) => state.user)

    const metaText = useMemo(() => {
        // return `post: ${data.id} ${data.community.title} (id: ${data.community.id})`
        return `post: ${data.id} `
    }, [data])

    const userText = useMemo(() => {
        return `${data.user.name ?? ''} ${data.user.surname ?? ''}`
    }, [data])

    const { add: addTicket } = useMutationTicket()

    const { blockUser } = useMutationBlocks()

    const { unsubscribeUser } = useMutationSubscribe()

    const handlerSubmit = ({
        [FieldName.isReportComment]: isReportComment,
        [FieldName.isUnsubscribe]: isUnsubscribe,
        [FieldName.isBlock]: isBlock,
        [FieldName.message]: message,
        ...params
    }: FormDataType) => {
        const additionalText = message ? `\n${message}` : ''

        return addTicketAction({
            generated_user_id: guid,
            member_id: user.member_id,
            member_name: `${user.name ?? ''} ${user.surname ?? ''}`,
            member_phone: user.user_phones.length ? user.user_phones[0].phone_number : '',
            member_email: user.email,
            app_version: `${APP_ID} ${APP_VERSION}`,
            os_version: window.navigator.userAgent,
            phone_model: window.navigator.userAgent,
            message_text: isReportComment
                ? `${t('complain_about_comment')} ${metaText} ${userText} (id: ${data.user.id}) ${additionalText}`
                : `${t('complain_about_user').replace('%s', userText)} (id: ${data.user.id}) ${metaText}${additionalText}`,
            ...params,
        })
            .then((res) => {
                if (isBlock) {
                    return blockUserAction({ userId: data.user.id })
                        .then(() => {
                            return Promise.resolve(res)
                        })
                        .catch((err) => {
                            onError(Array.isArray(err) ? err[0] : '')
                            return Promise.resolve(res)
                        })
                }

                return Promise.resolve(res)
            })
            .then((res) => {
                if (isUnsubscribe) {
                    return unsubscribeUserAction({ userId: data.user.id })
                        .then(() => {
                            return Promise.resolve(res)
                        })
                        .catch((err) => {
                            onError(Array.isArray(err) ? err[0] : '')
                            return Promise.resolve(res)
                        })
                }

                return Promise.resolve(res)
            })
            .then((res) => {
                onSuccess(res)
                return Promise.resolve(res)
            })
            .catch((err) => {
                onError(Array.isArray(err) ? err[0] : '')
                return Promise.reject(err)
            })
    }

    function addTicketAction(params: TAddTicketProps) {
        return addTicket.mutateAsync(params)
    }

    function blockUserAction(params: TBlockUserProps) {
        return blockUser.mutateAsync(params)
    }

    function unsubscribeUserAction(params: TUnsubscribeUserProps) {
        return unsubscribeUser.mutateAsync(params)
    }

    return (
        <Form
            initialValues={formDataInitial}
            onSubmit={handlerSubmit}
            render={({ handleSubmit, submitting }) => (
                <ReportAbuseCommentForm
                    isSubmitting={submitting}
                    userText={userText}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default ReportAbuseCommentAction
