import {
    UseQueryOptions,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { IPostCommentResponse } from 'interfaces'
import {
    TRequestError,
    TCommentsProps,
    TCommentsResponse,
    TAddCommentProps,
    TUpdateCommentProps,
    TUpdateCommentResponse,
    TDeleteCommentProps,
    TDeleteCommentResponse,
    TRestoreCommentProps,
    TRestoreCommentResponse,
} from 'services/CommunityService'
import { QueryCacheKeys } from 'enums'
import { CommunityService } from 'services'
import { getRequestError } from 'utils/helpers'
import { useAppSelector } from 'store'
import { useAppQueriesData } from 'queryClient'

const key = QueryCacheKeys.comments

export type TFetchFnParams = TCommentsProps
export type TQueryFnOpts = UseQueryOptions<
    TCommentsResponse,
    [string, TRequestError<TFetchFnParams>],
    TCommentsResponse,
    [string, TFetchFnParams]
>

/**
 * Хук API получение списка комментариев поста
 */
export default function useFetchComments(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return CommunityService.fetchComments(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useMutationComments() {
    const { t } = useTranslation()

    const add = useMutation<
        IPostCommentResponse,
        [string, TRequestError<TAddCommentProps>],
        TAddCommentProps,
        unknown
    >((props) => {
        return CommunityService.addComment(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const update = useMutation<
        TUpdateCommentResponse,
        [string, TRequestError<TUpdateCommentResponse>],
        TUpdateCommentProps,
        unknown
    >((props) => {
        return CommunityService.updateComment(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const remove = useMutation<
        TDeleteCommentResponse,
        [string, TRequestError<TDeleteCommentResponse>],
        TDeleteCommentProps,
        unknown
    >((props) => {
        return CommunityService.deleteComment(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const restore = useMutation<
        TRestoreCommentResponse,
        [string, TRequestError<TRestoreCommentResponse>],
        TRestoreCommentProps,
        unknown
    >((props) => {
        return CommunityService.restoreComment(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        add,
        update,
        remove,
        restore,
    }
}

export function useSetQueriesDataComments() {
    const user = useAppSelector((state) => state.user)

    const {
        setQueriesData,
        invalidateQueries,
    } = useAppQueriesData<TFetchFnParams, TCommentsResponse>(`${key}-${user.id}`)

    return {
        setQueriesData,
        invalidateQueries,
    }
}
