import {
    QueryClient,
    QueryFilters,
    SetDataOptions,
    InfiniteData,
    InvalidateQueryFilters,
    InvalidateOptions,
} from '@tanstack/react-query'
import { Updater } from '@tanstack/query-core/src/utils'

const RETRY_FAILURE_COUNT = 3

/**
 * Data fetching and state management instance
 */
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 300 * 1000, // 5m
            cacheTime: 600 * 1000, // 10m
            retry: (failureCount, error) => {
                const [, err] = Array.isArray(error) ? error : []
                const { status, response } = err || {}
                const statusError = status || response?.status

                if (statusError === 401 || statusError === 403 || statusError === 404) {
                    return false
                }

                return failureCount + 1 < RETRY_FAILURE_COUNT
            },
        },
    },
})

/**
 * Stores all the data, meta information and state of queries it contains
 */
export const useAppQueriesData = <FetchFnParams, QueryFnData>(queryKey: string) => {
    /**
     * Update cached data of multiple queries
     */
    const setQueriesData = (
        params: FetchFnParams,
        updater: Updater<
            QueryFnData | InfiniteData<QueryFnData> | undefined,
            QueryFnData | InfiniteData<QueryFnData> | undefined
        >,
        filter: QueryFilters = {},
        options: SetDataOptions = {},
    ) => {
        return queryClient.setQueriesData<QueryFnData | InfiniteData<QueryFnData>>({
            queryKey: [queryKey, params],
            ...filter,
        },
        updater,
        options)
    }

    /**
     * Invalidate and refetch multiple queries in the cache
     */
    const invalidateQueries = (
        params: FetchFnParams,
        { exact = true, ...filters }: InvalidateQueryFilters = {},
        options: InvalidateOptions = {},
    ) => {
        return queryClient.invalidateQueries([queryKey, params], { exact, ...filters }, options)
    }

    return {
        setQueriesData,
        invalidateQueries,
    }
}

export default queryClient
