import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TRequestError,
    TCommunityProps,
    TCommunityResponse,
    TUnsubscribeCommunityProps,
    TUnsubscribeCommunityResponse,
} from 'services/CommunityService'
import { QueryCacheKeys } from 'enums'
import { CommunityService } from 'services'
import { useAppSelector } from 'store'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TCommunityProps
export type TQueryFnOpts = UseQueryOptions<
    TCommunityResponse,
    [string, TRequestError<TFetchFnParams>],
    TCommunityResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.communities

/**
 * Hook API communities
 */
export default function useFetchCommunity(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return CommunityService.fetchCommunity(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useMutationCommunity() {
    const { t } = useTranslation()

    const unsubscribe = useMutation<
        TUnsubscribeCommunityResponse,
        [string, TRequestError<TUnsubscribeCommunityProps>],
        TUnsubscribeCommunityProps,
        unknown
    >((props) => {
        return CommunityService.unsubscribeCommunity(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        unsubscribe,
    }
}
