import { ThunkAction } from 'redux-thunk'

import { IRootState } from 'interfaces'
// eslint-disable-next-line
import {
    unsubscribeUserActionType,
    unsubscribeStoreActionType,
    unsubscribeStoreUserActionType,
    unsubscribeCommunityActionType,
    unsubscribeCompanyActionType,
} from './network-actions-type'
import {
    UNSUBSCRIBE_USER,
    UNSUBSCRIBE_STORE,
    UNSUBSCRIBE_STORE_USER,
    UNSUBSCRIBE_COMMUNITY,
    UNSUBSCRIBE_COMPANY,
} from 'reducers/types'
import { NetworkService, CommunityService } from 'services'
import {
    TUnsubscribeUserProps,
    unsubscribeStorePropType,
    unsubscribeStoreUserPropType,
    unsubscribeCompanyPropType,
} from 'services/NetworkService'
import { TUnsubscribeCommunityProps } from 'services/CommunityService'
import errorLog from 'utils/errorLog'

/**
 * @deprecated
 */
export function unsubscribeUser(params: TUnsubscribeUserProps)
    : ThunkAction<Promise<void>, IRootState, unknown, unsubscribeUserActionType> {
    return async (dispatch) => {
        NetworkService.unsubscribeUser(params).then(() => {
            dispatch({ type: UNSUBSCRIBE_USER, payload: params.userId })
        }).catch((err) => {
            errorLog('unsubscribeUser', err)
        })
    }
}

/**
 * @deprecated
 */
export function unsubscribeStore(params: unsubscribeStorePropType)
    : ThunkAction<
        Promise<void>,
        IRootState,
        unknown,
        unsubscribeStoreActionType
    > {
    return async (dispatch) => {
        NetworkService.unsubscribeStore(params).then(() => {
            dispatch({ type: UNSUBSCRIBE_STORE, payload: params.storeId })
        }).catch((err) => {
            errorLog('unsubscribeStore', err)
        })
    }
}

/**
 * @deprecated
 */
export function unsubscribeStoreUser(params: unsubscribeStoreUserPropType)
    : ThunkAction<
        Promise<void>,
        IRootState,
        unknown,
        unsubscribeStoreUserActionType
    > {
    return async (dispatch) => {
        NetworkService.unsubscribeStoreUser(params).then(() => {
            dispatch({ type: UNSUBSCRIBE_STORE_USER, payload: params })
            // TODO ? REMOVE_POSTS_FROM_STATE REMOVE_CHECKS_FROM_STATE
        }).catch((err) => {
            errorLog('unsubscribeStoreUser', err)
        })
    }
}

/**
 * @deprecated
 */
export function unsubscribeCommunity(params: TUnsubscribeCommunityProps)
    : ThunkAction<
        Promise<void>,
        IRootState,
        unknown,
        unsubscribeCommunityActionType
    > {
    return async (dispatch) => {
        CommunityService.unsubscribeCommunity(params).then(() => {
            dispatch({ type: UNSUBSCRIBE_COMMUNITY, payload: params.communityId })
        }).catch((err) => {
            errorLog('unsubscribeCommunity', err)
        })
    }
}

/**
 * @deprecated
 */
export function unsubscribeCompany(params: unsubscribeCompanyPropType)
    : ThunkAction<
        Promise<void>,
        IRootState,
        unknown,
        unsubscribeCompanyActionType
    > {
    return async (dispatch) => {
        NetworkService.unsubscribeCompany(params).then(() => {
            dispatch({ type: UNSUBSCRIBE_COMPANY, payload: params.companyId })
        }).catch((err) => {
            errorLog('unsubscribeCompany', err)
        })
    }
}
