import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IPostComment, IPostCommentGroup, ITicketItem } from 'interfaces'
import { EVENT_TYPE_POST_COMMENT_REPLY } from 'config/app'
import {
    Comment,
    Loader,
    ErrorMsg,
    Modal,
} from 'components'
import { useMutationBlocks } from 'containers/Network/hooks'
import { ReportAbuseCommentAction } from 'form-actions'
import { useAlertDialog } from 'hooks'
import { showAlertNotify } from 'utils/helpers'
import { useAppSelector } from 'store'
import eventBus from 'utils/EventBus'
import { useFetchComments, useMutationComments, useSetQueriesDataComments } from './hooks'
import style from './Comments.module.css'

type CommentsPropType = {
    classes?: string
    postId: number
    isLoad?: boolean
    onLoad?: (comments: IPostComment[]) => void
}

function getGroupId(commentId: number): string {
    return `group_${commentId}_${Date.now()}`
}

/**
 * Группирование коммента с ответами на него (дочерние комменты)
 */
const setCommentGroup = (groups: IPostCommentGroup[], parentId: number, comment: IPostComment)
    : IPostCommentGroup[] => {
    return groups.map((group) => {
        if (group.parent.id === parentId) {
            const child = { id: getGroupId(comment.id), parent: comment, children: [] }
            const children = group?.children?.length ? [...group.children, child] : [child]

            return { ...group, children }
        }
        if (group.children?.length) {
            return { ...group, children: setCommentGroup(group.children, parentId, comment) }
        }

        return group
    })
}

/**
 * Comments module
 * @event EVENT_TYPE_POST_COMMENT_REPLY - reply comment
 */
const Comments: React.FC<CommentsPropType> = ({
    classes,
    postId,
    isLoad = false,
    onLoad = () => {},
}) => {
    const { t } = useTranslation()
    const { showAlertDialog } = useAlertDialog()

    const user = useAppSelector((state) => state.user)

    const [commentProps, setCommentProps] = useState<IPostComment>()
    const [isOpenModalAbuseForm, setIsOpenModalAbuseForm] = useState(false)

    const {
        isInitialLoading: isLoading,
        data: dataComments,
        error: errorComments,
    } = useFetchComments({
        postId,
    }, {
        enabled: isLoad,
        cacheTime: 600 * 1000, // 10m
        staleTime: 60 * 1000, // 1m
    })

    const {
        update: updateComment,
        remove: removeComment,
        restore: restoreComment,
    } = useMutationComments()

    const {
        invalidateQueries: invalidateQueriesComments,
        setQueriesData: setQueriesDataComments,
    } = useSetQueriesDataComments()

    const { blockUser, unblockUser } = useMutationBlocks()

    const commentGroups: IPostCommentGroup[] = useMemo(() => {
        if (!dataComments?.comment_list.length) {
            return []
        }

        return dataComments.comment_list.reduce((acc: IPostCommentGroup[], comment): IPostCommentGroup[] => {
            if (comment.parent) {
                return setCommentGroup(acc, comment.parent, comment)
            }

            return [...acc, { id: getGroupId(comment.id), parent: comment, children: [] }]
        }, [])
    }, [dataComments])

    const handlerCommentReply = (parent: IPostComment) => {
        eventBus.emit(EVENT_TYPE_POST_COMMENT_REPLY, { parent })
    }

    const handlerCommentEdit = (commentId: number, comment: string) => {
        updateCommentAction(commentId, comment)
    }

    const handlerCommentDelete = (commentId: number, message: string) => {
        showAlertDialog({
            message,
            onConfirm: () => deleteCommentAction(commentId),
        })
    }

    const handlerCommentRestore = (commentId: number, message: string) => {
        showAlertDialog({
            message,
            onConfirm: () => restoreCommentAction(commentId),
        })
    }

    const handlerBlockUser = (userId: number, message: string = '') => {
        showAlertDialog({
            message,
            onConfirm: () => blockUserAction(userId),
        })
    }

    const handlerUnBlockUser = (userId: number, message: string = '') => {
        showAlertDialog({
            message,
            onConfirm: () => unBlockUserAction(userId),
        })
    }

    const handlerReportAbuse = (value: IPostComment) => {
        setCommentProps(value)
        setIsOpenModalAbuseForm(true)
    }

    const handlerReportAbuseComplete = (value: ITicketItem) => {
        setIsOpenModalAbuseForm(false)
        invalidateQueriesComments({ postId })
        showAlertNotify({ type: 'success', message: t('complaint_report_sent_message') })
    }

    const handlerError = (value: string) => {
        showAlertNotify({ type: 'error', message: value })
    }

    function updateCommentAction(commentId: number, comment: string) {
        updateComment.mutate({ id: commentId, comment }, {
            onSuccess: () => {
                setQueriesDataComments({ postId }, (cacheData) => {
                    if (cacheData && 'comment_list' in cacheData) {
                        return {
                            ...cacheData,
                            comment_list: cacheData.comment_list.map((item) => {
                                return item.id === commentId ? { ...item, comment } : item
                            }),
                        }
                    }
                    return cacheData
                })
            },
            onError: ([errorText]) => {
                handlerError(errorText)
            },
        })
    }

    function deleteCommentAction(commentId: number) {
        removeComment.mutate({ id: commentId }, {
            onSuccess: () => {
                invalidateQueriesComments({ postId })
            },
            onError: ([errorText]) => {
                handlerError(errorText)
            },
        })
    }

    function restoreCommentAction(commentId: number) {
        restoreComment.mutate({ id: commentId }, {
            onSuccess: () => {
                invalidateQueriesComments({ postId })
            },
            onError: ([errorText]) => {
                handlerError(errorText)
            },
        })
    }

    function blockUserAction(userId: number) {
        blockUser.mutate({ userId }, {
            onSuccess: (res) => {
                invalidateQueriesComments({ postId })
                showAlertNotify({ type: 'success', message: res })
            },
            onError: ([errorText]) => {
                handlerError(errorText)
            },
        })
    }

    function unBlockUserAction(userId: number) {
        unblockUser.mutate({ userId }, {
            onSuccess: (res) => {
                invalidateQueriesComments({ postId })
                showAlertNotify({ type: 'success', message: res })
            },
            onError: ([errorText]) => {
                handlerError(errorText)
            },
        })
    }

    useEffect(() => {
        if (dataComments) {
            onLoad(dataComments.comment_list)
        }
    }, [dataComments])

    return (
        <>
            <div className={classes}>
                {isLoading && (
                    <Loader />
                )}

                {!isLoading && errorComments && (
                    <ErrorMsg error={errorComments[0]} />
                )}

                {commentGroups.map((group) => (
                    <React.Fragment key={group.id}>
                        <Comment
                            data={group.parent}
                            isOwn={group.parent.user.id === user.id}
                            key={group.parent.id}
                            onCommentReply={handlerCommentReply}
                            onCommentEdit={handlerCommentEdit}
                            onCommentDelete={handlerCommentDelete}
                            onCommentRestore={handlerCommentRestore}
                            onBlockUser={handlerBlockUser}
                            onUnBlockUser={handlerUnBlockUser}
                            onReportAbuse={handlerReportAbuse}
                        />

                        {group?.children.map((child) => (
                            <Comment
                                isReply
                                data={child.parent}
                                isOwn={child.parent.user.id === user.id}
                                key={child.parent.id}
                                onCommentReply={handlerCommentReply}
                                onCommentEdit={handlerCommentEdit}
                                onCommentDelete={handlerCommentDelete}
                                onCommentRestore={handlerCommentRestore}
                                onBlockUser={handlerBlockUser}
                                onUnBlockUser={handlerUnBlockUser}
                                onReportAbuse={handlerReportAbuse}
                            />
                        ))}
                    </React.Fragment>
                ))}
            </div>

            <Modal
                isOpen={isOpenModalAbuseForm}
                size="medium"
                onClose={() => setIsOpenModalAbuseForm(false)}
            >
                <Modal.Header
                    isCloseButton
                    classes={style.modalHeader}
                    classesTitle={style.modalTitle}
                    titlePos="left"
                    title={t('report_content')}
                />
                <Modal.Body classes={style.modalBody}>
                    {commentProps && (
                        <ReportAbuseCommentAction
                            data={commentProps}
                            onError={handlerError}
                            onSuccess={handlerReportAbuseComplete}
                        />
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Comments
