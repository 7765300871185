import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import { IPostComment, ICommentProps } from 'interfaces'
import { EVENT_TYPE_POST_COMMENT_REPLY } from 'config/app'
import { CommentAction } from 'form-actions'
import { useSetQueriesDataComments } from 'containers/Comments/hooks'
import { UserMessageFormWrap, TextContent, Button } from 'components'
import { scrollTo } from 'utils/helpers'
import { useAppSelector } from 'store'
import eventBus from 'utils/EventBus'
import style from './CommentForm.module.css'

type CommentFormPropType = {
    classes?: string
    postId: number
}

const CommentForm: React.FC<CommentFormPropType> = ({ classes, postId }) => {
    const ref = useRef<HTMLDivElement>(null)

    const user = useAppSelector((state) => state.user)

    const [commentProps, setCommentProps] = useState<ICommentProps>({ postId, comment: '', parent: undefined })
    const [parentComment, setParentComment] = useState<IPostComment>()
    const [isFocus, setIsFocus] = useState(false)

    const { invalidateQueries: invalidateQueriesComments } = useSetQueriesDataComments()

    const handlerCompleteComment = () => {
        resetCommentProps()
        invalidateQueriesComments({ postId }).then(() => {
            scrollBottom()
        })
    }

    const handlerCommentReplyCancel = () => {
        resetCommentProps()
    }

    const handlerEventPostCommentReply = ({ parent }: { parent: IPostComment }) => {
        setCommentProps((prevState) => ({ ...prevState, parent: parent.id }))
        setParentComment(parent)
        scrollBottom()
    }

    function resetCommentProps() {
        setCommentProps((prevState) => ({ ...prevState, comment: '', parent: undefined }))
        setParentComment(undefined)
        setIsFocus(false)
    }

    function scrollBottom() {
        setTimeout(() => {
            if (ref.current) {
                setIsFocus(true)
                scrollTo(ref.current.offsetTop)
            }
        })
    }

    useEffect(() => {
        eventBus.on(EVENT_TYPE_POST_COMMENT_REPLY, handlerEventPostCommentReply)

        return () => {
            eventBus.off(EVENT_TYPE_POST_COMMENT_REPLY, handlerEventPostCommentReply)
        }
    }, [])

    return (
        <div className={cn(style.form, classes)} ref={ref}>
            {parentComment && (
                <div className={style.textReply}>
                    <Button
                        classes={style.textReplyClose}
                        styleType="transparent"
                        onClick={handlerCommentReplyCancel}
                    >
                        <span className={style.textReplyCloseIcon} />
                    </Button>
                    <div className={style.textReplyBody}>
                        <div className={style.textReplyUser}>
                            {`${parentComment?.user.name ?? ''} ${parentComment?.user.surname ?? ''}`}
                        </div>
                        <div className={style.textReplyComment}>
                            <TextContent
                                content={parentComment?.comment}
                            />
                        </div>
                    </div>
                </div>
            )}

            <UserMessageFormWrap classes={style.formFieldWrap} user={user}>
                <CommentAction
                    commentProps={commentProps}
                    isFocus={isFocus}
                    onComplete={handlerCompleteComment}
                />
            </UserMessageFormWrap>
        </div>
    )
}

export default CommentForm
