import React, { useMemo } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { IPost, ITicketItem } from 'interfaces'
import { FormDataType, FieldName, formDataInitial } from 'forms/ReportAbusePostForm/ReportAbusePostForm'
import { TAddTicketProps } from 'services/SupportService'
import { TUnsubscribeCommunityProps } from 'services/CommunityService'
import { APP_ID, APP_VERSION } from 'config/app'
import { ReportAbusePostForm } from 'forms'
import { useMutationTicket } from 'containers/Support/hooks'
import { useMutationCommunity } from 'containers/Community/hooks'
import { useUserGUID } from 'hooks'
import { useAppSelector } from 'store'

type AbuseActionPropType = {
    data: IPost
    onError?: (value: string) => void
    onSuccess?: (value: ITicketItem) => void
}

const ReportAbusePostAction: React.FC<AbuseActionPropType> = ({
    data,
    onSuccess = () => {},
    onError = () => {},
}) => {
    const { t } = useTranslation()
    const guid = useUserGUID()

    const user = useAppSelector((state) => state.user)

    const metaText = useMemo(() => {
        return `post: ${data.id} ${data.community.title} (id: ${data.community.id})`
    }, [data])

    const communityText = useMemo(() => {
        return data.community.title
    }, [data])

    const { add: addTicket } = useMutationTicket()

    const { unsubscribe: unsubscribeCommunity } = useMutationCommunity()

    const handlerSubmit = ({
        [FieldName.isReportPost]: isReportPost,
        [FieldName.isUnsubscribe]: isUnsubscribe,
        [FieldName.message]: message,
        ...params
    }: FormDataType) => {
        const additionalText = message ? `\n${message}` : ''

        return addTicketAction({
            generated_user_id: guid,
            member_id: user.member_id,
            member_name: `${user.name ?? ''} ${user.surname ?? ''}`,
            member_phone: user.user_phones.length ? user.user_phones[0].phone_number : '',
            member_email: user.email,
            app_version: `${APP_ID} ${APP_VERSION}`,
            os_version: window.navigator.userAgent,
            phone_model: window.navigator.userAgent,
            message_text: isReportPost
                ? `${t('complain_about_post')} ${metaText}${additionalText}`
                : `${t('complain_about_community').replace('%s', communityText)} ${metaText}${additionalText}`,
            ...params,
        })
            .then((res) => {
                if (isUnsubscribe) {
                    return unsubscribeCommunityAction({ communityId: data.community.id })
                        .then(() => {
                            return Promise.resolve(res)
                        })
                        .catch((err) => {
                            onError(Array.isArray(err) ? err[0] : '')
                            return Promise.resolve(res)
                        })
                }

                return Promise.resolve(res)
            })
            .then((res) => {
                onSuccess(res)
                return Promise.resolve(res)
            })
            .catch((err) => {
                onError(Array.isArray(err) ? err[0] : '')
                return Promise.reject(err)
            })
    }

    function addTicketAction(params: TAddTicketProps) {
        return addTicket.mutateAsync(params)
    }

    function unsubscribeCommunityAction(params: TUnsubscribeCommunityProps) {
        return unsubscribeCommunity.mutateAsync(params)
    }

    return (
        <Form
            initialValues={formDataInitial}
            onSubmit={handlerSubmit}
            render={({ handleSubmit, submitting }) => (
                <ReportAbusePostForm
                    isSubmitting={submitting}
                    metaText={metaText}
                    communityText={communityText}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default ReportAbusePostAction
